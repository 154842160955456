import { AppBar, Box, Container, Menu, MenuItem, Toolbar, Tooltip, IconButton } from "@mui/material"
import { Link } from "react-router-dom"

import React, { useState } from "react"

import logo from '../assets/webplogo.webp'
import MenuIcon from '@mui/icons-material/Menu';

const menuItems = [
    {
        title: 'Etusivu',
        path: '/'   
    },
    {
        title: 'Lyhennin',
        path: '/lyhennin'
    },
    {
        title: 'Luo QR-koodi',
        path: '/qr'
    },
    {
        title: 'Etsi linkki',
        path: '/lyhennin/etsi',
    }
]

const Navbar = () => {

    const [anchorEl, setAnchorEl] = useState(null)

    return (
        <AppBar position="static">
            <Container maxWidth="x1">
                <Toolbar disableGutters>

                    <Box sx={{ flexGrow: 1 }}>

                        <Link style={{ marginTop: '5px' }} to={'/'}>
                            <img src={logo} alt="Logo of the site" style={{ height: '2rem', marginRight: '0.5rem' }} />
                        </Link>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title='Avaa valikko'>
                            <IconButton
                                size='large'
                                edge='start'
                                color='inherit'
                                aria-label='menu'
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                            >
                                <MenuIcon  />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            sx={{ mt: '45px' }}
                            id="navMenu"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            {menuItems.map(item => (
                                <MenuItem key={item.title} component={Link} to={item.path} onClick={() => setAnchorEl(null)}>{item.title}</MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    

                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navbar