import { Box, Typography, Container, Button, Divider, Grid } from "@mui/material";
import { Link } from "react-router-dom";

import React from "react";

const Index = () => {
    return (
        <div>
            <Box sx={{ 
                bgcolor: 'background.paper',
                pt: 8,
                pb: 4
             }}>
                <Container maxWidth='sm' sx={{ pb: 4 }}>
                    <Typography
                        variant="h2"
                        component="h1"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Linkinlyhennin
                    </Typography>
                    
                    <Typography variant='h5' component='p' align='center' color='text.secondary' paragraph>
                        Lyhennä pitkät linkit lyhyiksi, omannäköisiksi ja helposti muistettaviksi. 
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button sx={{ mr: 0.5 }} variant='outlined' color='primary' component={Link} to='/lyhennin'>
                            Lyhennä linkki
                        </Button>
                        <Button sx={{ ml: 0.5 }} variant='outlined' color='secondary' component={Link} to='/qr'>
                            Luo QR-koodi
                        </Button>
                    </Box>
                </Container>

                <Divider />

                <Grid container>


                    <Grid item sm={6}>

                    <Box sx={{ pt: 4, maxWidth: '95%' }}>
                        <Typography variant='h5' component='h5' align='center' paragraph gutterBottom>Miksi <i>juuri</i> Linkki.tk?</Typography>	
                        <Typography variant='body1' component='p' align='center' color='text.secondary' paragraph>
                            Linkki.tk on helppo ja nopea tapa lyhentää linkkejä. Linkki.tk on täysin ilmainen, eikä se vaadi rekisteröitymistä. Jos et ole vielä vakuuttunut, niin kokeile itse. Se on täysin ilmaista.
                        </Typography>
                    </Box>
                    
                    </Grid>

                    <Grid item sm={6}>

                        <Box sx={{ pt: 4, maxWidth: '95%' }}>
                        <Typography variant='h5' align='center' component='h6' paragraph gutterBottom>Juuri <i>sinulle</i> sopivat linkit.</Typography>	
                            <Typography align='center' variant='body1' component='p' color='text.secondary' paragraph>
                                Oletko kyllästynyt kirjoittamaan satunnaisia kirjaimia ja numeroita lyhytlinkin perään? Linkki.tk:n avulla voit luoda juuri sinulle sopivan, omannäköisen lyhyen linkin.
                            </Typography>
                        </Box>

                    </Grid>

                </Grid>


            </Box>


        </div>
    )
}

export default Index