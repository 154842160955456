import React, { useState } from "react";
import { TextField, Typography, Box, InputAdornment, Button, LinearProgress, Card, CardContent, CardActions } from "@mui/material";
import { getLink } from "../Services/LinkService";

const Search = ({ messages }) => {
    const [searchResults, setResults] = useState(false)
    const [id, setId] = useState('')

    const loadContent = async () => {
        setResults(true)
        try {
            const linkPromise = await getLink(id)
            if (!linkPromise.data || !linkPromise.data.destination) {
                throw Error('Invalid content')
            } else {
                setResults(linkPromise.data)
            }
        } catch (e) {
            console.log(e)
            messages.error('Linkkiä ei löytynyt!')
            setResults(false)
            
        }
    }

    return (
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h2' component='h1'>Etsi linkki</Typography>
            <Typography variant='h6' component='h5' color='text.secondary'>Tällä työkalulla voit etsiä tiettyä linkkiä järjestelmästämme.</Typography>

            <Box sx={{ mt: 4 }}>
                <TextField
                    label='Linkin pääte'
                    InputProps={{
                        startAdornment: <InputAdornment position='start'>linkki.tk/</InputAdornment>
                    }}

                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    
                    inputProps={{ maxLength: 20 }}
                />


            </Box>

            <Button disabled={id.length < 1} sx={{ mt: 1.5 }} onClick={loadContent} variant="contained">Hae linkkiä</Button>
            
            <SearchResults results={searchResults} />
        </Box> 
    )
}

const SearchResults = ({ results }) => {
    // false = do not show anything
    // true = downloading
    // object = content

    if (results === false) {
        return
    } else if (results === true) {
        return (
            <LinearProgress sx={{ mt: 4, width: '500px' }} />
        )
    }

    return (
        <Card sx={{ minWidth: 275, mt: 4 }}>
            <CardContent>
                <Typography sx={{ fontsize: 14 }} color='text.secondary'>Haullesi löytyi tuloksia!</Typography>
                <Typography variant='h5' component='p' sx={{ maxWidth: '220px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {results.destination}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={() => window.location.href = results.destination} size="small">Siirry sivustolle</Button>
            </CardActions>
        </Card>

    )
}

export default Search
