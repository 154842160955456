import { createTheme, CssBaseline, ThemeProvider } from "@mui/material"
import { Container } from "@mui/system"

import { Outlet, useLocation } from "react-router-dom"
import Navbar from "../Components/Navbar"

import { useState, useEffect } from "react"

import TitleChanger from "../Scripts/titleChanger"


const darkTheme = createTheme({
    palette: {
        mode: 'dark'
    }
})

const Layout = ({ Alerts }) => {
    const location = useLocation()

    const [pathName, setPathName] = useState(useLocation.pathName)

    useEffect(() => {
        setPathName(location.pathname)
   }, [location])

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline>
                <Navbar />
                <Alerts />
                <TitleChanger routePath={pathName} />
                <Container fixed>
                    <Outlet />
                </Container>
            </CssBaseline>
        </ThemeProvider>
    )
}

export default Layout