import { Typography } from '@mui/material';
import React from 'react';

const Tos = () => {
    return (
        <div>
            <Typography component='h1' variant='h2'>Terms of service</Typography>

            <hr />

            <Typography component='p' variant='h5'>Information that we collect when creating a new link</Typography>
            <Typography component='p'>
                When you create a new link we collect the following information:
                <ul>
                    <li>Destination URL</li>
                    <li>Custom URL postfix</li>
                </ul>
            </Typography>

            
            <Typography sx={{ mt: 2 }} component='p' variant='h5'>Advertisements</Typography>
            <Typography component='p'>
                We are using Google AdSense. AdSense uses cookies to serve ads based on a user's prior visits to your website. Google's use of advertising cookies enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet. Users may opt out of personalized advertising by visiting Ads Settings.
            </Typography>
        </div>
    )
}

export default Tos