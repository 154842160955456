
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingBackdrop from "../Components/LoadingBackdrop";

import { getLink } from "../Services/LinkService";
import { useNavigate } from "react-router-dom";
import { Link, Typography } from "@mui/material";

const RedirectSite = ({ messages }) => {
    const [linkObject, setLinkObject] = useState(null);
    const { id } = useParams();

    const navigate = useNavigate();


    const err = (message) => {
        messages.error(message)
        setLinkObject(false)
    }

    
    if (linkObject && linkObject.destination) {
        window.location.replace(linkObject.destination);
    }

    useEffect(() => {
        getLink(id)
            .then((response) => {
                setLinkObject(response.data);
        })
            .catch((error) => {    
                if (error.response.status === 404) {
                    err("Linkkiä ei löytynyt")
                    setTimeout(() => {
                        navigate("/")
                    }, 5000)
                } else if (error.response && error.response.data && error.response.data.error) {
                    return err(error.response.data.error);
                } else {
                    return err("Tuntematon virhe");
                }
        })
    

    }, [id])

    return (
        <div>
            {linkObject ? <RedirectingScreen url={linkObject.destination} /> : <LoadingBackdrop open={linkObject !== false} />}
        </div>
    )

}

const RedirectingScreen = ({ url }) => (
    <div>
        <Typography variant='h2' component='h1'>Olet pian perillä</Typography>
        <Typography variant='subtitle1' component='p'>Sinut ohjataan pian osoitteeseen <Link href={url}>{url}</Link> Klikkaa linkistä, jos et pian ole kohteessa.</Typography>
    </div>
)

export default RedirectSite