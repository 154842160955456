import axios from "axios";
const BASE_URL = 
    process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api/linkki/' : 'https://linkkiapi.herokuapp.com/api/linkki/'

const getLink = async (id) => {
    const response = await axios.get(BASE_URL + id);
    return response
}

const postLink = async (url, customID, captchakey) => {
    return await axios.post(BASE_URL, { url, customID, captchakey });
}

const getLinkList = async () => {
    const response = await axios.get(BASE_URL)
    return response
}

export { getLink, postLink, getLinkList };