import React, { useState, useRef } from "react";

import { Box, Container, Typography, Button, TextField, InputAdornment, LinearProgress, Link as MUILink } from "@mui/material";
import { postLink } from "../Services/LinkService";

import { Link } from "react-router-dom";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import Fireworks from "@fireworks-js/react";

const baseURL = 
    process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://linkki.tk/'

const Lyhennin = ({ messages }) => {
    const [linkDestination, setDestination] = useState('')
    const maxLinkLength = 255

    const [customName, setCustomName] = useState('')
    const maxCustomName = 15

    const [captchaKey, setCaptchaKey] = useState(null)

    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(null)

    const capthchaRef = useRef(null)

    const isValidInput = () => {
        return linkDestination && captchaKey && !loading
    }

    const submitForm = (e) => {
        e.preventDefault()
        setLoading(true)

        postLink(linkDestination, customName, captchaKey)
            .then(res => {
                setResult(res.data)
                setLoading(false)

                messages.success('Linkin luominen onnistui!')
            })
            .catch(err => {
                setResult(null)
                setLoading(false)

                if (err.response && err.response.status === 409) {
                    messages.error("Samanpäätteinen linkki on jo olemassa. Kokeile vaihtaa päätettä.")
                }
                else if (err.response && err.response.data && err.response.data.error) {
                    messages.error(err.response.data.error)
                } else {
                    messages.error("Tuntematon virhe")
                }

                capthchaRef.current.resetCaptcha()
            })
    }

    const handleCustomNameChange = (e) => {
        if (e.target.value.length < maxCustomName) {
            setCustomName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase())
        }
    }

    if (result) {
        return <CreationSucceed messages={messages} result={result} />
    }

    return (
        
            <Box sx={{ 
                bgcolor: 'background.paper',
                pt: 6,
                pb: 4
             }}>
                <Typography
                        variant="h2"
                        component="h1"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Lyhennin
                    </Typography>
                <Container maxWidth='sm'>


                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>

                        <Box component='form' sx={{ mt: 1 }} onSubmit={(e) => submitForm(e)}>
                            <TextField 
                                value={linkDestination}
                                onChange={(e) => setDestination(e.target.value)}

                                helperText={`Esimerkiksi: https://www.example.com (${linkDestination.length}/${maxLinkLength} merkkiä)`}
                                inputProps={{
                                    maxLength: maxLinkLength
                                }}

                                margin="normal"
                                required
                                fullWidth
                                id="destination"
                                label="Lyhennettävä linkki"
                                name="destination"
                                autoComplete="destination"
                                autoFocus

                                type='url'

                                sx={{}}
                                disabled={loading}
                            />

                            <TextField 
                                value={customName}
                                onChange={(e) => handleCustomNameChange(e)}

                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>linkki.tk/</InputAdornment>
                                }}

                                helperText={`Anna linkille pääte. Jätä tyhjäksi, jos et halua valita omaa päätettä. (${customName.length}/${maxCustomName} merkkiä)`}

                                margin="normal"
                                fullWidth
                                id="customname"
                                label="Linkin osoite"
                                name="customname"
                                autoComplete="customname"

                                sx={{}}
                                disabled={loading}
                            />


                                <Box style={{ display: linkDestination ? 'flex' : 'none', justifyContent: 'center' }}>
                                    <HCaptcha
                                        sitekey="c7a83ea3-b02f-4112-bf94-a77f9d9d519f"
                                        onVerify={(token) => setCaptchaKey(token)}
                                        theme='dark'
                                        ref={capthchaRef}
                                    />
                                </Box>
                            
                            {loading ? <LinearProgress sx={{ mt: 1.5 }} /> : <SubmitButton validate={isValidInput()} />}
                            <Typography variant='caption' color='text.secondary'>Luomalla linkin hyväksyt <MUILink component={Link} to='/lyhennin/termsofservice'>käyttöehdot</MUILink>.</Typography>


                        </Box>
                    </Box>

 
                </Container>

                        
            </Box>
    )
}

const SubmitButton = ({ validate }) => <Button disabled={!validate} type='submit' fullWidth variant='contained' sx={{ mt: 1, mb: 2 }}>Luo linkki</Button>

const CreationSucceed = ({ result, messages }) => {

    const link = baseURL + result.linkID
    const shareLink = async () => {
        try {
            const data = {
                title: `Lyhytlinkkini`,
                text: 'Tässä lyhytlinkkini.',
                url: link
            }

            await navigator.share(data)
        
    } catch (e) {
            messages.error('Linkin jakaminen epäonnistui.')
            console.error(e)
        }
    }


    return (
        <div>
            <Fireworks enabled={true} style={{
                left: 0,
                width: '100%',
                height: '100%',
                position: 'fixed',
                zIndex: -1,
                backgroundColor: 'black',
                opacity: 0.25
            }} options={{ intensity: 11.26 }} />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Typography sx={{ pt: 4 }} variant='h2' component='h1' align='center' color='text.primary' gutterBottom>
                    Linkin luominen onnistui!
                </Typography>
                
                <Typography variant='subtitle1' align='center' color='text.primary' gutterBottom>Loit juuri äsken uuden lyhytlinkin. Onnittelut! Voit kopioida tai jakaa sen alta.</Typography>


                <TextField
                    value={link}
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{ width: '320px', textAlign: 'center' }}
                    align='center'
                />

                <Button onClick={shareLink} sx={{ mt: 2 }} variant='contained' disabled={!navigator.canShare}>Jaa</Button>
                    
            </Box>
        </div>
    )
}

export default Lyhennin