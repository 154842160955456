import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Index from "./Pages/Index";
import Lyhennin from "./Pages/Lyhennin";
import RedirectSite from "./Pages/RedirectSite";

import Layout from "./Pages/Layout";
import AlertCenter from "./Components/AlertCenter";
import Tos from "./Pages/Tos";
import Search from "./Pages/Etsi";
import QrCodeSite from "./Pages/QrCode";


// titleRoutings at Scripts/titleChanger.js

const App = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const messages = {
    success: (message) => {
      setSuccessMessage(message);
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000)
    },
    error: (message) => {
      setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }
  
  return (
    <Routes>

      <Route path="/" element={<Layout Alerts={() => <AlertCenter successMessage={successMessage} errorMessage={errorMessage} />} />}>
        <Route index element={<Index />} />
        <Route path="lyhennin">
          <Route index element={<Lyhennin messages={messages} />} />
          
          <Route path="termsofservice" element={<Tos />} />
          <Route path="etsi" element={<Search messages={messages} />} />
        </Route>
        <Route path=":id" element={<RedirectSite messages={messages} />} />

        <Route path="qr" element={<QrCodeSite />} />

        <Route path='*' element={<p>404</p>} />
      </Route>
    </Routes>
  )
}

export default App