import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

let defaultValue = 'Linkki.tk'
let defaultDescValue = 'Lyhennä vaikeasti muistettavat linkit lyhyemmiksi, omannäköisiksi ja helposti muistettavaksi. Kokeile itse!'

let value = defaultValue

const titleRoutings = [
    {
        url: '/',
        title: 'Etusivu',
        description: 'Lyhennä vaikeasti muistettavat linkit lyhyemmiksi, omannäköisiksi ja helposti muistettavaksi. Kokeile itse!'
    },
    {
        url: '/lyhennin',
        title: 'Lyhennin',
        description: 'Linkki.tk:n virallinen lyhennystyökalu, jolla teet linkistä lyhyemmän. Ei enää pitkiä linkkejä!'
    },
    {
        url: '/lyhennin/termsofservice',
        title: 'Käyttöehdot',
        description: 'Linkki.tk:n käyttöehdot käyttäjillemme.'
    },
    {
        url: '/qr',
        title: 'Luo QR-koodi',
        description: 'Oletko koskaan miettinyt, miten luoda QR-koodi? Linkki.tk:n työkalun avulla luot sellaisen helposti.'
    },
    {
        url: '/lyhennin/etsi',
        title: 'Etsi',
        description: 'Oletko kiinnostunut, mihin Linkki.tk:n lyhytlinkki johtaa? Nyt voit selvittää sen klikkaamatta linkkiä.'
    }
]

const TitleChanger = ({ routePath }) => {
    const descriptionTag = document.getElementById('description')

    const changeTitle = (title) => {
        document.title = `${title} - Linkinlyhennin`
    }

    let location = useLocation()

    useEffect(() => {
        const handleTitleChange = (titleObject) => {
            if (titleObject && titleObject.title) {
                value = titleObject.title
            } else {
                value = defaultValue
            }
    
            changeTitle(value)
            handleDescChange(titleObject)

        }

        const handleDescChange = (object) => {

            if (object && object.description) {
                descriptionTag.content = object.description
            } else {
                descriptionTag.content = defaultDescValue
            }
        }

        const findTitle = () => {
            return titleRoutings.find(title => title.url === routePath)
        }

        handleTitleChange(findTitle())

    }, [routePath, location, descriptionTag])

}
export default TitleChanger