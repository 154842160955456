import React from "react";
import { Alert } from "@mui/material";

const AlertCenter = ({ successMessage=null, errorMessage=null }) => (
    <>
        {successMessage && <Success message={successMessage} />}
        {errorMessage && <Error message={errorMessage} />}
    </>
)

const Error = ({ message }) => (
    <Alert severity="error">
        {message}
    </Alert>
)

const Success = ({ message }) => (
    <Alert severity="success">
        {message}
    </Alert>
)

export default AlertCenter