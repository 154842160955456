import { Box, Button, Card, CardActions, CardContent, TextField, Typography, Divider } from '@mui/material'
import { useRef } from 'react'
import { useState } from 'react'
import QRCode from 'react-qr-code'

import ReactToPrint from 'react-to-print'

const QrCodeSite = () => {
    const [urlField, setField] = useState('')
    const [url, setUrl] = useState(null)

    const createUrl = (e) => {
        e.preventDefault()
        setUrl(urlField)
    }

    return (
        <Box sx={{ pt: 6, pb: 4 }}>
            <Typography variant='h2' component='h1'>QR-koodin luominen</Typography>
            <Typography variant='h6' color='text.secondary' component='p'>
            Luo QR-koodi helposti alla olevan työkalun avulla!
            </Typography>

            <Card sx={{ mt: 1, maxWidth: '600px' }} component='form' onSubmit={(e) => createUrl(e)}>
                <CardContent>
                    <TextField helperText='Muista https -tai http-alku!' type='url' variant='standard' value={urlField} onChange={(e) => setField(e.target.value)} label='URL-osoite' />
                    <Button variant='contained' type='submit' size='large' sx={{ mt: 0.75, ml: 0.5 }}>Luo QR-koodi</Button>
                    <QrCodeView url={url} />
                </CardContent>
            </Card>

            <Divider sx={{ mt: 2 }} />

            <Typography sx={{ mt: 2 }} variant='h4' component='h2'>Miten luoda oma QR-koodi?</Typography>
            <ol>
                <li>Kirjoita linkki URL-osoitekenttään</li>
                <li>Paina 'Lue QR-koodi' -napista</li>
                <li>Valmista!</li>
            </ol>

            <p>Näin sinulla on oma QR-koodi, jota jonka voit tulostaa, tai jakaa vaikkapa internetissä.</p>
        </Box>
    )
}

const QrCodeView = ({ url }) => {
    const qrCode = useRef()


    if (!url) {
        return null
    }


    return (
        <Box sx={{ mt: 1 }}>

            
            <QRCode ref={qrCode} bgColor='#0f171c' fgColor='#b8b8b8' value={url}/>
            <CardActions>
                    <ReactToPrint
                        trigger={() => <Button color='primary'>Tulosta</Button>}
                        content={() => qrCode.current}
                />
            </CardActions>
        </Box>
    )
}

export default QrCodeSite